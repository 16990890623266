<template>
  <div class="min-h-screen my-3 flex items-center justify-center">
    <div class="max-w-5xl w-full h-full p-6 rounded-lg shadow-md bg-white vld-parent">

      <loading
        :active="isUserLoading || isUserRegistering || regDataLoading"
        :is-full-page="false"
        :loader="'bars'"
        :color="'#00afd3'"
        :height="90"
        :width="90"
        :opacity="0.7"
      />

      <img class="mx-auto h-20 w-auto" src="@/assets/img/logo.png" alt="Workflow" />

      <h2 class="mt-4 text-center text-3xl leading-9 font-extrabold text-primary-500">
        Регистрация
      </h2>

      <RegistrationForm v-if="!user" @formready="regDataLoading = false" />

      <RegistrationDataExist v-if="registerStatus == 'fail'" mail="mail@mail.ru"/>
      
      <!--<RegFootnote /> -->

    </div>
  </div>
</template>

<script>
import RegistrationForm from '@/components/registration/RegistrationForm.vue'
import RegistrationSuccess from '@/components/registration/RegistrationSuccess.vue'
import RegistrationDataExist from '@/components/registration/RegistrationDataExist.vue'
import RegAuthorized from '@/components/registration/RegAuthorized.vue'
import RegFootnote from '@/components/registration/RegFootnote.vue'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import { mapState, mapGetters } from 'vuex'
import { FadeTransition, SlideXLeftTransition } from 'vue2-transitions'

export default {
  name: 'Registration',
  components: {
    RegistrationForm,
    RegistrationSuccess,
    RegistrationDataExist,
    RegAuthorized,
    RegFootnote,
    Loading,
    FadeTransition,
    SlideXLeftTransition
  },
  data: function() {
    return {
      regDataLoading: true
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      registerStatus: state => state.auth.registerStatus
    }),
    ...mapGetters({
      isUserLoading: 'auth/isUserLoading',
      isUserRegistering: 'auth/isUserRegistering'
    })
  },
  watch: {
    user: function() {
      if (this.user) {
        this.$router.replace({name: 'Account'})
      }
    }
  }
}
</script>

<style>
</style>
