<template>
  <div>
    <p class="mt-6 text-sm leading-5 text-gray-600 text-left">
      После регистрации ваш запрос будет обработан администратором и вы получите подтверждение своих прав доступа на указанный адрес электронной почты.<br>
      Вы можете воспользоваться Гостевым входом. Однако, при использовании гостевого входа Вы получите доступ только к рубрикатору видео-библиотеки.
    </p>
  </div>
</template>

<script>
export default {
  name: 'RegFootnote',
}
</script>

<style scoped>
</style>
