<template>
  <form class="mt-8 lg:px-4" @submit.prevent="validateForm()">
    <div class="flex flex-wrap">

      <!-- Данные аккаунта -->
      <div class="w-full md:w-1/2 md:pr-4 lg:pr-8">
        <h2 class="font-semibold text-xl text-left pb-3 text-secondary">Аккаунт</h2>

        <!-- Email field -->
        <div class="relative">
          <input
            aria-label="Email address"
            v-model="user.email"
            @change="$v.user.email.$touch"
            v-bind:class="{ 'focus:border-red-500 text-red-700' : $v.user.email.$error || blackList,
              'focus:border-green-300 text-primary-500' : !$v.user.email.$error && !blackList }"
            class="appearance-none relative block w-full px-3 py-2 border placeholder-gray-500
            text-gray-900 rounded-md focus:outline-none focus:shadow-outline-blue focus:z-10 sm:text-sm
            sm:leading-5"
            placeholder="Адрес электронной почты"
          />

          <!-- Input field validation icon with animation -->
          <slide-x-left-transition :duration="300" mode="out-in">
            <div v-if="$v.user.email.$dirty" class="absolute right-0 top-0 mr-4 ml-auto mt-2">
              <slide-x-left-transition :duration="300" mode="out-in">
                <font-awesome-icon v-if="!$v.user.email.$error && !blackList" key="1" icon="check" class="text-green-700"/>
                <font-awesome-icon v-if="$v.user.email.$error || blackList" key="2" icon="times" class="text-red-700"/>
              </slide-x-left-transition>
            </div>
          </slide-x-left-transition>

          <!-- Error message -->
          <collapse-transition :duration="300" mode="out-in">
            <div v-if="$v.user.email.$error || blackList" class="text-red-700 mt-0 text-sm">
              <span v-if="!$v.user.email.email">Неправильный формат e-mail!</span>
              <span v-if="!$v.user.email.required">Требуется заполнить e-mail!</span>
              <span v-if="blackList">Доступ запрещен!</span>
            </div>
          </collapse-transition>
        </div>

        <!-- Password field -->
        <div class="mt-2 relative">
          <input
            aria-label="Password"
            v-model="user.password"
            @change="$v.user.password.$touch"
            v-bind:class="{ 'focus:border-red-500 text-red-700' : $v.user.password.$error,
              'focus:border-green-300 text-primary-500' : !$v.user.password.$error }"
            type="password"
            class="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500
            text-gray-900 rounded-md focus:outline-none focus:shadow-outline-blue focus:z-10 sm:text-sm
            sm:leading-5"
            placeholder="Пароль"
          />

          <!-- Input field validation icon with animation -->
          <slide-x-left-transition :duration="300" mode="out-in">
            <div v-if="$v.user.password.$dirty" class="absolute right-0 top-0 mr-4 ml-auto mt-2">
              <font-awesome-icon v-if="!$v.user.password.$error" icon="check" class="text-green-700"/>
              <font-awesome-icon v-if="$v.user.password.$error" icon="times" class="text-red-700"/>
            </div>
          </slide-x-left-transition>

          <!-- Error message -->
          <collapse-transition :duration="300" mode="out-in">
            <div v-if="$v.user.password.$error" class="text-red-700 mt-0 text-sm">
              <span v-if="!$v.user.password.minLength">Пароль должен быть минимум 6 символов!</span>
              <span v-if="!$v.user.password.required">Требуется заполнить пароль!</span>
            </div>
          </collapse-transition>
        </div>

        <!-- Password confirmation field -->
        <div class="mt-2 relative">
          <input
            aria-label="Password"
            v-model="password_confirmed"
            @change="$v.password_confirmed.$touch"
            v-bind:class="{ 'focus:border-red-500 text-red-700' : $v.password_confirmed.$error,
              'focus:border-green-300 text-primary-500' : !$v.password_confirmed.$error }"
            type="password"
            class="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500
            text-gray-900 rounded-md focus:outline-none focus:shadow-outline-blue focus:z-10 sm:text-sm
            sm:leading-5"
            placeholder="Повторите пароль"
          />

          <!-- Input field validation icon with animation -->
          <slide-x-left-transition :duration="300" mode="out-in">
            <div v-if="$v.password_confirmed.$dirty" class="absolute right-0 top-0 mr-4 ml-auto mt-2">
              <font-awesome-icon v-if="!$v.password_confirmed.$error" icon="check" class="text-green-700"/>
              <font-awesome-icon v-if="$v.password_confirmed.$error" icon="times" class="text-red-700"/>
            </div>
          </slide-x-left-transition>

          <!-- Error message -->
          <collapse-transition :duration="300" mode="out-in">
            <div v-if="$v.password_confirmed.$error" class="text-red-700 mt-0 text-sm">
              <span v-if="!$v.user.password.required">Не введён пароль!</span>
              <span v-if="!$v.password_confirmed.sameAsPassword">Пароли не совпадают!</span>
            </div>
          </collapse-transition>
        </div>

      </div>

      <!-- Личные данные -->
      <div class="w-full md:w-1/2 md:pl-4 lg:pl-8 md:border-l border-gray-300">
        <h2 class="pt-6 md:pt-0 font-semibold text-xl text-left pb-3 text-secondary">Личные данные</h2>

        <!-- ФИО -->
        <div class="relative">
          <input
            aria-label="Name"
            v-model="user.name"
            @change="$v.user.name.$touch"
            v-bind:class="{ 'focus:border-red-500 text-red-700' : $v.user.name.$error,
              'focus:border-green-300 text-primary-500' : !$v.user.name.$error }"
            class="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500
            text-gray-900 rounded-md focus:outline-none focus:shadow-outline-blue focus:z-10 sm:text-sm
            sm:leading-5"
            placeholder="Фамилия Имя Отчество"
          />

          <!-- Input field validation icon with animation -->
          <slide-x-left-transition :duration="300" mode="out-in">
            <div v-if="$v.user.name.$dirty" class="absolute right-0 top-0 mr-4 ml-auto mt-2">
              <font-awesome-icon v-if="!$v.user.name.$error" icon="check" class="text-green-700"/>
              <font-awesome-icon v-if="$v.user.name.$error" icon="times" class="text-red-700"/>
            </div>
          </slide-x-left-transition>

          <!-- Error message -->
          <collapse-transition :duration="300" mode="out-in">
            <div v-if="$v.user.name.$error" class="text-red-700 mt-0 text-sm">
              <span v-if="!$v.user.name.required">Требуется ввести как минимум фамилию и имя!</span>
              <span v-if="!$v.user.name.fio">Требуется ввести как минимум фамилию и имя!</span>
            </div>
          </collapse-transition>
        </div>

        <!-- Город -->
        <div class="relative mt-2">
          <input
            aria-label="City"
            v-model="user.city"
            @change="$v.user.city.$touch"
            @input="getCitySuggestions()"
            v-bind:class="{ 'focus:border-red-500 text-red-700' : $v.user.city.$error,
              'focus:border-green-300 text-primary-500' : !$v.user.city.$error }"
            class="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500
            text-gray-900 rounded-md focus:outline-none focus:shadow-outline-blue focus:z-10 sm:text-sm
            sm:leading-5"
            placeholder="Город"
          />

          <!-- Input field validation icon with animation -->
          <slide-x-left-transition :duration="300" mode="out-in">
            <div v-if="$v.user.city.$dirty" class="absolute right-0 top-0 mr-4 ml-auto mt-2">
              <font-awesome-icon v-if="!$v.user.city.$error" icon="check" class="text-green-700"/>
              <font-awesome-icon v-if="$v.user.city.$error" icon="times" class="text-red-700"/>
            </div>
          </slide-x-left-transition>

          <!-- Error message -->
          <collapse-transition :duration="300" mode="out-in">
            <div v-if="$v.user.city.$error" class="text-red-700 mt-0 text-sm">
              <span v-if="!$v.user.city.required">Введите город!</span>
            </div>
          </collapse-transition>
        </div>

        <!-- Speciality field -->
        <div class="flex mt-2">
          <div class="w-1/3 pl-3 pt-2 text-gray-500 text-sm text-left">
            Специальность:
          </div>
          <div class="w-2/3 flex items-center pl-3 flex-right">
            <select
              class="form-select relative block w-full px-3 pt-3 pb-2 border border-gray-300 placeholder-gray-500
              text-gray-900 rounded-md focus:outline-none text-primary-500 focus:shadow-outline-blue focus:border-blue-300
              focus:z-10 sm:text-sm sm:leading-5"
              v-model="user.speciality"
            >
              <option value="" hidden>Специальность</option>
              <option 
                v-for="speciality in specialities"
                :key="speciality.id"
                :value="speciality.id"
              >
                {{ speciality.name }}
              </option>
            </select>          
          </div>
        </div>

        <!-- Error message -->
        <collapse-transition :duration="300" mode="out-in">
          <div v-if="$v.user.speciality.$dirty && !$v.user.speciality.required" class="w-2/3 mr-0 ml-auto">
            <span class="text-sm text-red-700">Не выбрана специальность!</span>
          </div>
        </collapse-transition>

        <!-- Специальность (Другое) -->
        <div v-if="user.speciality === 18" class="relative mt-2">
          <input
            aria-label="Work"
            v-model="user.specialityName"
            @change="$v.user.specialityName.$touch"
            v-bind:class="{ 'focus:border-red-500 text-red-700' : $v.user.specialityName.$error,
              'focus:border-green-300 text-primary-500' : !$v.user.specialityName.$error }"
            class="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500
            text-gray-900 rounded-md focus:outline-none focus:shadow-outline-blue focus:z-10 sm:text-sm
            sm:leading-5"
            placeholder="Название специальности"
          />

          <!-- Input field validation icon with animation -->
          <slide-x-left-transition :duration="300" mode="out-in">
            <div v-if="$v.user.specialityName.$dirty" class="absolute right-0 top-0 mr-4 ml-auto mt-2">
              <font-awesome-icon v-if="!$v.user.specialityName.$error" icon="check" class="text-green-700"/>
              <font-awesome-icon v-if="$v.user.specialityName.$error" icon="times" class="text-red-700"/>
            </div>
          </slide-x-left-transition>

          <!-- Error message -->
          <collapse-transition :duration="300" mode="out-in">
            <div v-if="$v.user.specialityName.$error" class="text-red-700 mt-0 text-sm">
              <span v-if="!$v.user.specialityName.required">Заполните название специальности!</span>
            </div>
          </collapse-transition>
        </div>

        <!-- Поле должности (position) -->
        <div class="flex mt-2">
          <div class="w-1/3 pl-3 pt-2 text-gray-500 text-sm text-left">
            Должность:
          </div>
          <div class="w-2/3 flex items-center pl-3 flex-right">
            <select
              class="form-select relative block w-full px-3 pt-3 pb-2 border border-gray-300 placeholder-gray-500
              text-gray-900 rounded-md focus:outline-none text-primary-500 focus:shadow-outline-blue focus:border-blue-300
              focus:z-10 sm:text-sm sm:leading-5"
              v-model="user.position"
            >
              <option value="" hidden>Должность</option>
              <option 
                v-for="position in positions"
                :key="position.id"
                :value="position.id"
              >
                {{ position.name }}
              </option>
            </select>          
          </div>
        </div>

        <!-- Error message -->
        <collapse-transition :duration="300" mode="out-in">
          <div v-if="$v.user.position.$dirty && !$v.user.position.required" class="w-2/3 mr-0 ml-auto">
            <span class="text-sm text-red-700">Не выбрана должность!</span>
          </div>
        </collapse-transition>

        <!-- Должность (Другое) -->
        <div v-if="user.position === 0" class="relative mt-2">
          <input
            aria-label="Work"
            v-model="positionName"
            @change="$v.positionName.$touch"
            v-bind:class="{ 'focus:border-red-500 text-red-700' : $v.positionName.$error,
              'focus:border-green-300 text-primary-500' : !$v.positionName.$error }"
            class="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500
            text-gray-900 rounded-md focus:outline-none focus:shadow-outline-blue focus:z-10 sm:text-sm
            sm:leading-5"
            placeholder="Название должности"
          />

          <!-- Input field validation icon with animation -->
          <slide-x-left-transition :duration="300" mode="out-in">
            <div v-if="$v.positionName.$dirty" class="absolute right-0 top-0 mr-4 ml-auto mt-2">
              <font-awesome-icon v-if="!$v.positionName.$error" icon="check" class="text-green-700"/>
              <font-awesome-icon v-if="$v.positionName.$error" icon="times" class="text-red-700"/>
            </div>
          </slide-x-left-transition>

          <!-- Error message -->
          <collapse-transition :duration="300" mode="out-in">
            <div v-if="$v.positionName.$error" class="text-red-700 mt-0 text-sm">
              <span v-if="!$v.positionName.required">Заполните название должности!</span>
            </div>
          </collapse-transition>
        </div>

        <!-- Workplace field -->
        <div class="flex my-2">
          <div class="w-1/3 pl-3 pt-2 pb-3 text-gray-500 text-sm text-left">
            Место работы:
          </div>
          <div class="w-2/3 flex items-center pl-3 flex-right">
              <select 
                class="form-select relative block w-full px-3 pt-3 pb-2 border border-gray-300 placeholder-gray-500
                text-gray-900 rounded-md focus:outline-none text-primary-500 focus:shadow-outline-blue focus:border-blue-300
                focus:z-10 sm:text-sm sm:leading-5"
                v-model="user.workplace"
              >
                <option value="" hidden>Выберите место работы</option>
                <option
                  v-for="workplace in workplaces"
                  :key="workplace.id"
                  :value="workplace.id"
                >
                {{ workplace.name }}
              </option>
            </select>
          </div>
        </div>

        <!-- Error message -->
        <collapse-transition :duration="300" mode="out-in">
          <div v-if="$v.user.workplace.$dirty && !$v.user.workplace.required" class="w-2/3 mr-0 ml-auto">
            <span class="text-sm text-red-700">Не выбрано место работы!</span>
          </div>
        </collapse-transition>

        <!-- Место работы -->
        <div class="relative mt-2">
          <input
            aria-label="Work"
            v-model="user.work"
            @change="$v.user.work.$touch"
            v-bind:class="{ 'focus:border-red-500 text-red-700' : $v.user.work.$error,
              'focus:border-green-300 text-primary-500' : !$v.user.work.$error }"
            class="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500
            text-gray-900 rounded-md focus:outline-none focus:shadow-outline-blue focus:z-10 sm:text-sm
            sm:leading-5"
            placeholder="Наименование учреждения"
          />

          <!-- Input field validation icon with animation -->
          <slide-x-left-transition :duration="300" mode="out-in">
            <div v-if="$v.user.work.$dirty" class="absolute right-0 top-0 mr-4 ml-auto mt-2">
              <font-awesome-icon v-if="!$v.user.work.$error" icon="check" class="text-green-700"/>
              <font-awesome-icon v-if="$v.user.work.$error" icon="times" class="text-red-700"/>
            </div>
          </slide-x-left-transition>

          <!-- Error message -->
          <collapse-transition :duration="300" mode="out-in">
            <div v-if="$v.user.work.$error" class="text-red-700 mt-0 text-sm">
              <span v-if="!$v.user.work.required">Заполните наименование учреждения!</span>
            </div>
          </collapse-transition>
        </div>

      </div>
    </div>

    <!-- Submit form button -->
    <button
      v-if="!blackList"
      type="submit"
      class="group relative w-full md:w-1/2 mt-6 justify-center py-2 px-4 border border-transparent text-sm
        leading-5 font-medium rounded-md text-white bg-primary-600 hover:bg-primary-400 active:bg-primary-400
        transition duration-150 ease-in-out"
    >
      Отправить запрос
    </button>
    <router-link 
      to="/" 
      class="w-full md:w-1/2 mt-2 block ml-auto mr-auto py-2 px-4 border text-sm leading-5
      font-medium rounded-md text-primary-600 hover:text-primary-400 border-2 border-primary-600
      hover:border-primary-400 active:bg-primary-400 transition duration-150 ease-in-out"
    >
      Вернуться на сайт
    </router-link>

    <vue-recaptcha
      ref="recaptcha"
      size="invisible"
      :sitekey="'6Le9nfwUAAAAAF2tc1FbbNoq4ft9BU9vySq-xZxf'"
      @verify="submitForm"
      @expired="onCaptchaExpired"
    />
  </form>
</template>

<script>
import { SlideXLeftTransition, CollapseTransition } from 'vue2-transitions'

import { validationMixin } from 'vuelidate'
import { email, required, sameAs, minLength, requiredIf, helpers } from 'vuelidate/lib/validators'
import VueRecaptcha from 'vue-recaptcha'
import debounce from 'lodash/debounce'

const fio = helpers.regex('fio', /^[а-яa-zА-ЯA-Z\-]{1,}\s[а-яa-zА-ЯA-Z\-]{1,}(\s[а-яa-zА-ЯA-Z\-]{1,})?$/)

export default {
  components: { SlideXLeftTransition, CollapseTransition, VueRecaptcha },
  mixins: [validationMixin],
  name: 'RegistrationForm',
  data: function() {
    return {
      user: {
        email: '',
        password: '',
        name: '',
        city: '',
        work: '',
        speciality: '',
        specialityName: '',
        position: '',
        workplace: ''
      },
      positionName: null,
      password_confirmed: '',
      specialities: [],
      positions: [],
      workplaces: [],
      cities_suggestions: [],
      $script: null,
      badEmails:['nezhuchka@yandex.ru'],
    }
  },
  computed: {
    isLoading() {
      return this.specialities.length && this.positions.length && this.workplaces.length
    },
    blackList(){
      return this.badEmails.includes(this.user.email);  
    }
  },
  methods: {
    validateForm() {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.$refs.recaptcha.execute()
      }
    },
    submitForm(captchaToken) {
      if (this.user.position === 0)
        this.user.position = this.positionName

      const user = {
        ...this.user,
        captcha: captchaToken
      }

      this.$store.dispatch('auth/register', user)
        .then()
        .catch((error) => {
          if (error.response.status == 403) {
            this.$xmodal.open({
              component: require("@/components/registration/RegistrationDataExist.vue").default,
              backgroundColor: '#000000',
              opacity: '0.7',
              hasTimer: '5s',
              props: { mail: this.user.email }
            })
          }
        })
    },
    getCitySuggestions: debounce(function() {
      this.$store.getters.api.client({
        method: 'post',
        url: 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address',
        headers: { 'Authorization': 'Token 426779966bf31a844fb2d00331f611ef590780e8' },
        withCredentials: false,
        data: { query: this.user.city, from_bound: { value: "city" }, to_bound: { value: "settlement" } }
      })
      .then((response) => {
        console.log(response.data)
      })
    }, 500),
    onCaptchaExpired() {
      this.$refs.recaptcha.reset()
    }
  },
  validations: {
    user: {
      email: {
        email,
        required
      },
      password: {
        required,
        minLength: minLength(6)
      },
      name: {
        required,
        fio
      },
      city: {
        required
      },
      speciality: {
        required
      },
      specialityName: {
        required: requiredIf(function() {
          return this.user.speciality === 18
        }),
        minLength: minLength(3)
      },
      position: {
        required
      },
      workplace: {
        required
      },
      work: {
        required
      }
    },
    positionName: {
      required: requiredIf(function() {
        return this.user.position === 0
      }),
      minLength: minLength(3)
    },
    password_confirmed: {
      required,
      sameAsPassword: sameAs(function() {
          return this.user.password
        }
      )
    }
  },
  created: function() {
    this.$store.getters.api.getSpecialities()
      .then(data => {
        this.specialities = data
      })
    this.$store.getters.api.getPositions()
      .then(data => {
        this.positions = data
        this.positions.push({ id: 0, name: 'Другое' })
      })
    this.$store.getters.api.getWorkplaces()
      .then(data => {
        this.workplaces = data
      })
  },
  mounted: function() {
    this.$script = document.createElement('script')
    this.$script.async = true
    this.$script.src = 'https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit'
    document.head.appendChild(this.$script)
  },
  beforeDestroy: function() {
    document.head.removeChild(this.$script)
  },
  watch: {
    isLoading: function() {
      this.$emit('formready')
    }
  }
}
</script>
