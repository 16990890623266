<template>
  <div>
    <div class="bg-primary-600 rounded-lg px-3 py-3 mt-6 mb-4 text-white">
      <div class="bg-white w-10 h-10 rounded-full m-auto py-2 px-2 text-lg">
        <font-awesome-icon icon="check" class="text-primary-500"/>
      </div>
      <h2 class="text-xl font-semibold pt-4">Ваша заявка на получение доступа отправлена!</h2>
      <p class="pt-5">Ожидайте извещение об открытии доступа на указанный вами адрес электронной почты или просто
попробуйте войти на сайт с введенным паролем через некоторое время.</p>
    </div>
    <SolidButton title="Вернуться на сайт" link="/" />
  </div>
</template>

<script>
import SolidButton from '@/components/_partials/SolidButton.vue'
export default {
  name: 'RegistrationSuccess',
  components:{
    SolidButton
  }
}
</script>

<style scoped>
</style>
