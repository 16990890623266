<template>
  <div>
    <div class="bg-teal-600 rounded-lg px-3 py-3 mt-6 mb-4 text-white ">
      <div class="bg-white w-10 h-10 rounded-full m-auto py-2 px-2"><svg viewBox="0 0 4rem 4rem"></svg></div>
      <h2 class="text-xl font-semibold pt-4">Вы зашли как<br>{{ mail }}!</h2>
      <p class="pt-5">Вы можете продолжить работу с сайтом, либо выйти из своего аккаунта</p>
    </div>
    <div class="flex">
      <router-link 
                to="/"
                type="submit"
                class="group relative w-1/2 justify-center mr-1 py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md
                text-white bg-teal-600 hover:bg-teal-500 focus:outline-none focus:border-teal-600 focus:shadow-outline-teal
                active:bg-teal-600 transition duration-150 ease-in-out"
              >
              Вернуться на сайт
      </router-link>
      <router-link 
                to="/login"
                type="submit"
                class="group relative w-1/2 justify-center ml-1 py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md
                text-white bg-teal-600 hover:bg-teal-500 focus:outline-none focus:border-teal-600 focus:shadow-outline-teal
                active:bg-teal-600 transition duration-150 ease-in-out"
              >
              Выйти из аккаунта
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RegAuthorized',
  props: {
    mail: String
  }
}
</script>

<style scoped>
</style>
