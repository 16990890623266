import { render, staticRenderFns } from "./RegFootnote.vue?vue&type=template&id=375a7f11&scoped=true&"
import script from "./RegFootnote.vue?vue&type=script&lang=js&"
export * from "./RegFootnote.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "375a7f11",
  null
  
)

export default component.exports