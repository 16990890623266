<template>
  <div>
    <div class="bg-red-700 rounded-lg px-6 py-6 mt-6 mb-4 text-white w-64">
      <div class="bg-white w-10 h-10 rounded-full m-auto py-2 px-2 text-lg">
        <font-awesome-icon icon="times" class="text-red-700"/>
      </div>
      <h2 class="text-xl font-semibold pt-4">Ошибка!</h2>
      <p class="pt-5">Электронный адрес<br><span class="font-semibold">{{ mail }}</span><br>уже занят!</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RegistrationDataExist',
  props: {
    mail: String
  }
}
</script>

<style>
</style>
